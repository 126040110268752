import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from 'services/i18n'
import cn from 'classnames'
import Dotdotdot from 'react-dotdotdot'
import insert from 'ramda/es/insert'

import { SwipeCardActionButton, MatchAvatar } from 'components'
import { logEvent } from 'services/helpers'
import { ENTER_KEY_CODE, LEFT_ARROW_KEY_CODE, RIGHT_ARROW_KEY_CODE } from 'constants/common'

import c from './SavedCard.module.scss'


const selectCardAnim = (type) => type === 'skip' ? c.leftCardAnim : c.rightCardAnim

class SavedCard extends PureComponent {
  state = {
    animOut: false
  }

  onActionClick = () => {
    this.setState({ animOut: Math.random() + 1 })

    setTimeout(() => {
      const { onActionClick, logType } = this.props
      onActionClick()

      if (logType.includes('Skipped')) {
        logEvent('action.Saved.Like')
      } else if (logType.includes('Liked.OneWay')) {
        logEvent('action.Saved.OneWay.Skip')
      } else if (logType.includes('Liked.OneWay')) {
        logEvent('action.Saved.Mutual.Skip')
      }
    }, 100)
  }

  onRequestClick = () => {
    this.props.onRequestClick()
    logEvent(`action.${this.props.logType}.Request`)
  }

  onStartChatClick = () => {
    const { logType, onStartChatClick } = this.props
    onStartChatClick()
    logEvent(`action.${logType}.Chat`)
  }

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case LEFT_ARROW_KEY_CODE:
        if (this.props.onActionClick && this.props.action === "skip") {
          this.props.onActionClick();
        }
        break;
      case RIGHT_ARROW_KEY_CODE:
        if (this.props.onActionClick && this.props.action === "like") {
          this.props.onActionClick();
        }
        break;
      case ENTER_KEY_CODE:
        if (event.target.tagName === "ARTICLE") {
          this.props.onItemClick()
        }
        break;
      default:
        break
    }
  }

  render() {
    const { animOut } = this.state
    const {
      action, onItemClick, logType,
      onLikeClick,
      item,
      onSkipOneWayClick, onSkipMutualClick,
      data: { avatar, match, fullName, socialAccounts, claimed }
    } = this.props
    const username = fullName.length > 15
      ? insert(14, ' ', fullName).join('')
      : fullName
    const isGreen = match >= 50
    const isClaimed = claimed !== false


    return (
      <li className={cn(c.wrapper, animOut && c.animOut)}>
        <article
          tabIndex={0}
          onClick={onItemClick}
          onKeyDown={this.onKeyDown}
          className={cn(c.container, animOut && selectCardAnim(action))}
        >
          <h2 className={c.name}>
            <Dotdotdot clamp={1}>{username}</Dotdotdot>
          </h2>

          <MatchAvatar data={{ avatar, match, claimed: isClaimed }} red={!isGreen} />
          <div className={c.buttonsWrapper}>
            {action !== 'like' && <SwipeCardActionButton type="skip" onClick={logType === 'Liked.OneWay' ? onSkipOneWayClick : onSkipMutualClick} />}
            {isClaimed && (<SwipeCardActionButton type="chat" onClick={this.onStartChatClick} />)}
            <SwipeCardActionButton type="view" onClick={onItemClick} />
            <SwipeCardActionButton type="request" onClick={this.onRequestClick} />
            {action === 'like' && <SwipeCardActionButton type="like" onClick={onLikeClick} /> } 
            
          </div>

        </article>
      </li>
    )
  }
}

SavedCard.propTypes = {
  action: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  data: PropTypes.shape({
    avatar: PropTypes.string,
    match: PropTypes.number,
    fullName: PropTypes.string,
    description: PropTypes.string,
    common: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
}

export { SavedCard }
