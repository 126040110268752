import Dotdotdot from 'react-dotdotdot'
import cn from 'classnames'
import Eye from 'static/images/eye.svg'

import i18n from 'services/i18n'
import { Card } from 'lib/react-swipe-card'
import { getProfileImage } from 'services/redirectHelper'
import { MatchAvatar, SwipeCardActionButton } from 'components'
import c from './SwipeCard.module.scss'

const trimBio = (bio, maxLength = 250) => {
  if (!bio) return '';
  if (bio.length <= maxLength) return bio;
  return bio.substr(0, maxLength).trim() + '...';
};


const SwipeCard = (props) => {
  const { 
    item,
    onSkipButton,
    onLikeButton,
    onRequestRommate,
    isBioEnabled,
    onClick, ...other 
  } = props
  const { claimed, first_name, last_name, percent_match, membership_id, socialAccounts } = item
  const avatar = getProfileImage(item)
  const fullName = `${first_name} ${last_name}`
  const isGreen = percent_match >= 50

  const bio = !!isBioEnabled === true ?
  <div className={c.bioContainer}>
    <Dotdotdot clamp={4} className={c.bio}>
      {trimBio(item.bio) || i18n.t('profile.noBio')}
    </Dotdotdot>
  </div> : 
  <div className={c.bioContainer}>
    <Dotdotdot clamp={4} className={c.bio}>
    </Dotdotdot>
  </div>

  return (<span aria-label={`Potential roommate profile, ${fullName}, ${percent_match} ${i18n.t('profile.compatible')}`} aria-hidden={!props.active} aria-level={3}>
    <Card className={c.container} item={item} {...other} onClick={onClick(membership_id)}>
      <h2 className={c.name} aria-hidden={String(!props.active)}>
        <Dotdotdot clamp={1}>{fullName}</Dotdotdot>
      </h2>
      <MatchAvatar data={{ avatar, claimed, match: percent_match }} red={!isGreen} />

      {bio}
      
      
       
      <section>
        <div className={c.divider} />
        <div className={c.actions}>
          <SwipeCardActionButton type="skip" onClick={onSkipButton} />
          <SwipeCardActionButton type="view" onClick={onClick(membership_id)} />
          <SwipeCardActionButton type="request" onClick={onRequestRommate(item)} />
          <SwipeCardActionButton type="like" onClick={onLikeButton} />
        </div>
      </section>
    </Card>
    </span>);
}

export { SwipeCard }
