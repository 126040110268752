import { logEvent } from 'services/helpers'
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch/lib'
import omit from 'ramda/es/omit'
import i18n from 'services/i18n'
import { show } from 'redux-modal'
import { store } from '../../src'
import { chatActions } from 'redux/actions/ChatActions'
import { userActions } from 'redux/actions/UserActions'
import { ZendeskAPI } from "react-zendesk/src"
import { HOME } from 'constants/urls'
import { push } from 'connected-react-router'

const prepareRequestHeaders = ({ headers = {} }, token) => {
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  }

  if (token) {
    headers = {
      'RoomSync-Auth-Token': token,
      ...headers,
    }
  }

  return headers
}

const prepareURL = (targetUrl, query) => {
  if (!query) {
    return targetUrl
  }

  let queryUrl = `${targetUrl}?`
  for (let key in query) {
    if (query.hasOwnProperty(key)) {
      queryUrl = `${queryUrl}${key}=${query[key]}&`
    }
  }
  return queryUrl
}

const getToken = () => store?.getState()?.user?.auth?.accessToken
const clearToken = () => {
  try {
    const currentMethod = store?.getState()?.home?.loginType?.toLowerCase()

    store?.dispatch(userActions.updateRegisterData({
      networkId: null,
      subnetworkId: null,
      unitTypeId: null,
      roomTypeId: null,
    }))
    ZendeskAPI('messenger', 'reset')
    ZendeskAPI('messenger', 'prefill', {
      name: {
        value: '',
        readOnly: false // optional
      },
      email: {
        value: '',
        readOnly: false // optional
      },
    })
    store?.dispatch(chatActions.logoutChat())
    store?.dispatch(userActions.updateRegisterData({
      networkId: null,
      subnetworkId: null,
      unitTypeId: null,
      roomTypeId: null,
    }))
    store?.dispatch(userActions.resetStore())
    store?.dispatch(push(HOME, { currentMethod }))

    store?.dispatch(show(
      'infoModal',
      {
        logoutOnHide: true,
        label: i18n.t('profile.removedTitle'),
        description: (
          <p>
            {i18n.t('profile.removedInfo')} <a href='email:support@roomsync.com'>support@roomsync.com</a>
          </p>
        )
      }
    ))

    // google logout
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance()
      if (auth2 != null) {
        auth2.then(() => {
          auth2.signOut().then(auth2.disconnect)
        })
      }
    }
  } catch (err) {
  }
}

const fetchWrapper = (url, data = {}) => {
  data.body = JSON.stringify(data.body)

  data.headers = prepareRequestHeaders(data, getToken())

  const preparedUrl = prepareURL(process.env.REACT_APP_API_ENDPOINT + url, data.query)

  return fetchJSON(preparedUrl, data)
}

const shouldRefreshToken = () => true

const refreshToken = () => null

const fetchWithRefresh = configureRefreshFetch({
  refreshToken,
  shouldRefreshToken,
  fetch: fetchWrapper,
})

const requestWrapper = async (url, data = {}) => {
  try {
    const startTime = new Date().getTime()
    try {
      console.log('fetchWithRefresh', url, data)
      await fetchWithRefresh(url, data)
    }
    catch (error) {
      console.error('fetchWithRefresh error', error)
      throw error
    }
    const rawResponse = await fetchWithRefresh(url, data)
    console.log(rawResponse)

    const response = rawResponse?.response || {}
    const responseBody = rawResponse?.body || {}

    if (!response.ok) {
      logEvent('error', {
        ...omit(['headers'], data),
        code: response.status,
        endpoint: url,
        responseBody: JSON.stringify(responseBody),
      })

      if (response.status === 403) {
        return clearToken()
      }
    }

    logEvent('requestDuration', {
      code: response.status,
      endpoint: url,
      payload: data ? JSON.stringify(data) : null,
      responseBody: responseBody ? JSON.stringify(responseBody) : null,
      duration: new Date().getTime() - startTime,
    })

    return response.ok
      ? { res: responseBody, code: response.status }
      : { err: responseBody, code: response.status }
  } catch (error) {
    console.log(error)
    throw error
  }
}

const facebookAuth = (token, createOnLogin = false, invitationData) => requestWrapper('/auth/facebook', {
  query: {
    oauth_token: token,
    create_on_login: createOnLogin,
    ...invitationData
  },
  method: 'POST'
})

const googleAuth = (token, createOnLogin = false, invitationData) => requestWrapper('/auth/google', {
  query: {
    oauth_token: token,
    create_on_login: createOnLogin,
    ...invitationData
  },
  method: 'POST'
})

const appleAuth = (token, createOnLogin = false, kid, identityToken, invitationData) => requestWrapper('/auth/apple', {
  query: {
    oauth_token: token,
    public_key_id: kid,
    identityToken,
    create_on_login: createOnLogin,
    ...invitationData
  },
  method: 'POST'
})

const getProfile = (token, membershipId, targetMembershipId) => requestWrapper(`/users/${membershipId}/profile/${targetMembershipId}`, {}, token)

const refreshTokenSaga = (token) => requestWrapper(`/auth/refresh`, { method: 'POST' }, token)

const getProfiles = (token, membershipId, userIds, membershipIds) => requestWrapper(`/users/${membershipId}/profiles/v2`, { method: 'POST', body: { userIds, membershipIds } }, token)

const getUploadProfileImageUrl = (token, userId, query) => requestWrapper(`/users/${userId}/profile/image`, {
  query,
  method: 'GET'
}, token)

const getAboutMe = (token, membershipId) =>
  requestWrapper(`/v1/matching_group/questions/${membershipId}`, {}, token)

const getPotentialRoommates = (token, membershipId, data, search) =>
  requestWrapper(`/users/${membershipId}/potential_roommates`, {
    query: {
      status: 'queued',
      page: 0,
      ...search,
      ...data
    }
  }, token)

const setAboutMe = (token, membershipId, data) => requestWrapper(`/v1/matching_group/questions/${membershipId}`, {
  method: 'POST',
  body: data
}, token)

const generateAboutMe = ({ token, membershipId, questionId, data }) =>
  requestWrapper(`/v1/matching_group/questions/${membershipId}/help_me/${questionId}`, {
    method: 'GET',
    query: {
      /**
       * {
       *   personalities: [string?, string?, string?],
       *
       * : [string?, string?, string?],
       * }
       */
      ...data,
      length: 'SHORT', // Harcoded override for now
    },
  }, token)

const fetchRoomTypes = (
  networkId,
  subnetworkId,
  unitTypeId,
  token
) => requestWrapper(
  `/networks/${networkId}/subnetworks/${subnetworkId}/unit_types/${unitTypeId}/room_types`,
  {
    method: 'GET'
  }, token
)

const getMajorsContent = (token, networkId, subnetworkId) =>
  requestWrapper(`/networks/${networkId}/subnetworks/${subnetworkId}/majors`, {}, token)

const getProfileMajors = (token, membershipId) =>
  requestWrapper(`/users/${membershipId}/profile/majors`, {}, token)

const setMajorsOptions = (token, membershipId, majors) =>
  requestWrapper(`/users/${membershipId}/profile/majors`, {
    method: 'PUT',
    body: { majors }
  }, token)

const getSocialAccounts = (token, userId) =>
  requestWrapper(`/users/${userId}/profile/social_accounts`, {}, token)

const setSocialAccounts = (token, userId, socialAccounts) =>
  requestWrapper(`/users/${userId}/profile/social_accounts`, {
    method: 'PUT',
    body: { social_accounts: socialAccounts }
  }, token)

const getHousingOptions = (token, membershipId) =>
  requestWrapper(`/users/${membershipId}/profile/housing`, {}, token)

const setHousingOptions = (token, membershipId, housing) =>
  requestWrapper(`/users/${membershipId}/profile/housing`, {
    method: 'PUT',
    body: { housing }
  }, token)

const getHousingContent = (token, networkId, subnetworkId) =>
  requestWrapper(`/networks/${networkId}/subnetworks/${subnetworkId}/housing`, {}, token)

const getVerifyInfoContent = (token, membershipId) =>
  requestWrapper(`/users/${membershipId}/user_info_verification`, {}, token)

const setVerifyInfo = (token, membershipId, body) =>
  requestWrapper(`/users/${membershipId}/user_info_verification`, {
    method: 'PUT',
    body
  }, token)

const getRoomContent = (token, membershipId) =>
  requestWrapper(`/users/${membershipId}/my_room`, {}, token)

const leaveRoom = (token, membershipId) =>
  requestWrapper(`/users/${membershipId}/roommates`, {
    method: 'DELETE'
  }, token)

const acceptRoommateRequest = (token, membershipId, roommateId) =>
  requestWrapper(`/users/${membershipId}/incoming_requests/${roommateId}`, {
    method: 'POST',
    body: {}
  }, token)

const denyRoommateRequest = (token, membershipId, roommateId) =>
  requestWrapper(`/users/${membershipId}/incoming_requests/${roommateId}`, {
    method: 'DELETE',
    body: {}
  }, token)

const sendRoommateRequest = (token, membershipId, roommateId, message) =>
  requestWrapper(`/users/${membershipId}/outgoing_requests`, {
    method: 'POST',
    body: {
      potential_roommate_id: roommateId,
      message: message || ''
    }
  }, token)

const cancelRoommateRequest = (token, membershipId, roommateId) =>
  requestWrapper(`/users/${membershipId}/outgoing_requests/${roommateId}`, {
    method: 'DELETE',
    body: {}
  }, token)

const changeRoommateStatus = (token, membershipId, { item }, status) =>
  requestWrapper(`/users/${membershipId}/potential_roommates/${item.membership_id}/status`, {
    method: 'PUT',
    body: {
      status
    }
  }, token)

const setRoommate = (token, membershipId, roommateId) =>
  requestWrapper(`/users/${membershipId}/my_room/in_bedroom/${roommateId}`, {
    method: 'POST'
  }, token)

const deleteRoommate = (token, membershipId, roommateId) =>
  requestWrapper(`/users/${membershipId}/my_room/in_bedroom/${roommateId}`, {
    method: 'DELETE'
  }, token)

const setKnown = (token, membershipId, targetMembership) =>
  requestWrapper(`/user_membership/${membershipId}/previously_known/${targetMembership}`, {
    method: 'PUT'
  }, token)
const deleteKnown = (token, membershipId, targetMembership) =>
  requestWrapper(`/user_membership/${membershipId}/previously_known/${targetMembership}`, {
    method: 'DELETE'
  }, token)

const getProfileOverview = (token, membershipId, targetMembershipId) =>
  requestWrapper(`/users/${membershipId}/profile/${targetMembershipId}/overview`, {}, token)

const getChatSessionToken = (token, userId) =>
  requestWrapper(`/users/${userId}/chat/session`, {}, token)

const getCommunityContent = (token, networkId, subnetworkId) =>
  requestWrapper(`/networks/${networkId}/subnetworks/${subnetworkId}/details`, {}, token)

const getCommunityThreads = (token, networkId, subnetworkId) =>
  requestWrapper(`/networks/${networkId}/subnetworks/${subnetworkId}/threads`, {}, token)

const createCommunityThread = (token, membershipId, networkId, subnetworkId, message) =>
  requestWrapper(`/networks/${networkId}/subnetworks/${subnetworkId}/threads`, {
    body: {
      membership_id: membershipId,
      body: message
    },
    method: 'PUT'
  }, token)

const getCommunityPosts = (token, networkId, subnetworkId, { threadId }) =>
  requestWrapper(`/networks/${networkId}/subnetworks/${subnetworkId}/threads/${threadId}`, {}, token)

const createCommunityPost = (token, membershipId, networkId, subnetworkId, { threadId, message }) =>
  requestWrapper(`/networks/${networkId}/subnetworks/${subnetworkId}/threads/${threadId}`, {
    body: {
      membership_id: membershipId,
      body: message
    },
    method: 'PUT'
  }, token)

const getSearchValues = (token, membershipId) =>
  requestWrapper(`/users/${membershipId}/potential_roommates/settings`, {}, token)

const setSearchValues = (token, membershipId, newSettingsObject) =>
  requestWrapper(`/users/${membershipId}/potential_roommates/settings`, {
    method: 'PUT',
    body: newSettingsObject
  }, token)

const reassociate = (token, { passcode }) =>
  requestWrapper(`/auth/reassociate/user?passcode=${passcode}`, {
    method: 'POST'
  }, token)

const getInvitationPreflight = (passcode) =>
  requestWrapper(`/api/v1/invitation/preflight/${passcode}`, {
    method: 'GET'
  })

const deleteAccount = (token) =>
  requestWrapper('/users', {
    method: 'DELETE'
  }, token)

const getLoginMethod = (email) =>
  requestWrapper(`/api/v1/lost_auth?email=${email}`, {
    method: 'GET',
  })

export default {
  reassociate,
  leaveRoom,
  getAboutMe,
  setAboutMe,
  generateAboutMe,
  getProfile,
  getProfiles,
  googleAuth,
  appleAuth,
  setKnown,
  deleteKnown,
  setRoommate,
  facebookAuth,
  deleteAccount,
  setVerifyInfo,
  getRoomContent,
  fetchRoomTypes,
  deleteRoommate,
  getLoginMethod,
  getSearchValues,
  setSearchValues,
  getProfileMajors,
  refreshTokenSaga,
  getMajorsContent,
  setMajorsOptions,
  setHousingOptions,
  getCommunityPosts,
  getHousingOptions,
  getSocialAccounts,
  setSocialAccounts,
  getHousingContent,
  getProfileOverview,
  getCommunityThreads,
  createCommunityPost,
  denyRoommateRequest,
  getCommunityContent,
  sendRoommateRequest,
  getChatSessionToken,
  getVerifyInfoContent,
  changeRoommateStatus,
  getPotentialRoommates,
  acceptRoommateRequest,
  createCommunityThread,
  cancelRoommateRequest,
  getUploadProfileImageUrl,
  getInvitationPreflight
}
